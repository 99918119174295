import {createSlice} from '@reduxjs/toolkit'
import React from "react";

//定义初始化状态
let initialState = {
    //菜单导航
    'home':'/',//主页
    'shop':'/shop',//商店首页
    'photo':'/photo',//相册首页
    'weblog':'/weblog',//博客首页

    //用户地址管理
    'user_quit':'/user/user/quit',//退出登录
    'user':'/user/',//用户信息
    'user_address':'/user/address',//用户收货地址
    'user_level':'/user/level',//用户等级
    'user_data':'/user/data',//用户数据
    'user_messages':'/user/messages',//用户数据
    'user_order':'/user/order',//用户订单
    'user_cart':'/user/cart',//用户购物车
    'user_collections':'/user/collections',//用户搜藏
    'user_follow_list':'/user/follow_list',//用户关注列表
    'user_feedback':'/user/feedback',//问题反馈
}
export const app = createSlice({
    name: 'link_data',
    initialState,
    reducers: {
        /*设置菜单数据*/
        set_link_data: (state, params) => {
            state.link_data = params['payload']
        },
    },
})
// reducer方法的每一个case都会生成一个Action
export const { set_link_data} = app.actions
export default app.reducer
