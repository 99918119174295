import { configureStore } from '@reduxjs/toolkit';
import user_data from './user/user_data';
import link_data from './link_data';
import menu_data from './admin/menu_data';
import admin_data from './admin/admin_data';
export default configureStore({
    reducer: {
        'menu_data': menu_data,//菜单数据
        'admin_data': admin_data,//菜单数据
        'user_data': user_data,//用户数据
        'link_data': link_data,//跳转连接
    },
})
