/*
    主路由
    作者:chenpinzhong
    开发备注:实现网站所有路由
*/
import {useRoutes} from "react-router-dom" //用户路由
import {lazy} from "react";

const IndexRoute = lazy(() => import("./pages/index/route"))//网站路由
const UserRoute = lazy(() => import("./pages/user/route"))//用户路由
const ShopRoute = lazy(() => import("./pages/shop/route"))//店铺路由
const PhotoRoute = lazy(() => import("./pages/photo/route"))//相册路由
const AdminRoute = lazy(() => import("./pages/admin/route"))//后台路由
const StoreRoute = lazy(() => import("./pages/store/route"))//商店路由
const ChatRoute = lazy(() => import("./pages/chat/route"))//在线客服
const PayRoute = lazy(() => import("./pages/pay/route"))//在线客服

const MainRoutes = () => {
    return useRoutes([
        //网站首页
        {path: '/*', element: <IndexRoute/>,},
        //用户模块
        {path: '/user/*', element: <UserRoute/>,},
        //商店模块
        {path: '/shop/*', element: <ShopRoute/>,},
        //相册模块
        {path: '/photo/*', element: <PhotoRoute/>,},
        //管理模块
        {path: '/admin/*', element: <AdminRoute/>,},
        //商店模块
        {path: '/store/*', element: <StoreRoute/>,},
        //客服模块
        {path: '/chat/*', element: <ChatRoute/>,},
        //付款模块
        {path: '/pay/*', element: <PayRoute/>,},
        //所有路径不匹配 404
        {path: '/*', element: <div>路由 404</div>,}
    ])
}
export default MainRoutes
