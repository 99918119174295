import {createSlice} from '@reduxjs/toolkit'
import default_user_avatar from "__SRC__/images/head_portrait.jpg";//默认用户头像
//定义初始化状态
let initialState = {
    'id':'',
    'account_name':'',
    'user_avatar':default_user_avatar,//用户头像
}
//从本地存储拿到用户信息
let admin_data=localStorage.getItem('admin_data');//本地保存用户数据
if(typeof(admin_data)=="string" && admin_data.length>0){
    let temp_data=JSON.parse(admin_data);//解析json数据
    if(temp_data!==null && 'id' in temp_data)initialState=temp_data;
}
export const app = createSlice({
    name: 'admin_data',
    initialState,
    reducers: {
        /*设置菜单数据*/
        set_admin_data: (state, params) => {
            let data={...params['payload']};
            //初始化基础数据
            localStorage.setItem('admin_data',JSON.stringify(data));//本地保存用户数据
            if(state===null)state={};
            //更新对象值
            const keys = Object.keys(data);
            for (const key of keys) {
                state[key]=data[key];
            }
        },
    },
})
// reducer方法的每一个case都会生成一个Action
export const {set_admin_data} = app.actions
export default app.reducer
